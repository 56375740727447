.zabacus-footer {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 30px;
  color: #767676;
  text-align: center;
  border-top: 1px solid #e5e5e5;
}
.zabacus-footer-links {
  padding-left: 0;
  margin-top: 20px;
}
.vertical-align {
  display: flex;
  align-items: center;
}
.table tbody>tr>td {
  vertical-align: middle;
}

.member-link .avatar {
  float: left;
  margin-top: 1px;
  margin-right: 10px;
}

.member-name {
  display: block;
}

.amount {
  display: block;
}

.event-name {
  display: block;
  font-size: large;
}

.member-info {
  display: block;
  float: right;
}

.status {
  font-size: large;
}

.status-settled {
  color: #767676;
}

.status-open {
  color: green;
}

.status-dispute {
  color: red;
}

.list-group-item .owner-marker {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: 6.5px;
  color: #767676;
}

.list-group-item .surplus {
  border-style: none;
  border-width: 1px;
  border-radius: 4px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: 6.5px;
  color: #17D117;
}

.list-group-item .deficit {
  border-style: none;
  border-width: 1px;
  border-radius: 4px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: 6.5px;
  color: #F71E5B;
}

.dropdown-wide {
  padding: 17px;
  min-width: 400px;
  min-height: 300px;
}

.dropdown-wide .form-control {
  margin-bottom: 17px;
}

.nav-tabs {
  margin-bottom: 17px;
}

.h1, h1 {
  margin-top: 10px;
}

.breadcrumb {
  margin-bottom: 0px;
}

@media (min-width: 1200px) {
  .container .container-body {
    width: 1140px;
  }
}

a {
  cursor: pointer;
}

.caret.caret-reversed {
    border-top-width: 0;
    border-bottom: 4px solid #000000;
}

.in-form-margin {
  margin-bottom: 17px;
}

.in-form-margin.first-row {
  margin-top: 17px;
}

div.input-group.debt-weight {
  width: 190px;
}

div.container.new-item {
  max-width: 750px;
}

input.debt-weight {
  text-align: center;
}

textarea.form-control {
  resize: none;
}

button.calc {
  width: 100%;
}

div.calc {
  margin-bottom: 15px;
}

input.calc {
  text-align: right;
}

div.container-form {
  max-width: 350px;
}

.navbar-no-margin {
  margin-bottom: 0px;
}

.breadcrumb {
  margin-top: 20px;
}

.navbar-brand {
  font-family: Trajan Pro, Trajan, Lithos Pro Regular, Palatino, Palatino Linotype, Georgia;
}

.sign-btn {
  width: 76px;
  margin-right: 15px;
}

.nav-tabs > li.active > span,
.nav-tabs > li.active > span:focus,
.nav-tabs > li.active > span:hover {
  color: #555;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.nav-tabs > li > span {
  cursor: pointer;
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav > li > span {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav > li > span:focus, .nav > li > span:hover {
  text-decoration: none;
  background-color: #eee;
}

.nav-tabs > li > span:hover {
  border-color: #eee #eee #ddd;
}

.dropdown-menu > li > span {
  cursor: pointer;
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-menu > li > span:hover {
  text-decoration: none;
  background-color: #eee;
}

.navbar-nav > li > span {
	padding-top: 15px;
	padding-bottom: 15px;
}

.navbar-inverse .navbar-nav > .open > span,
.navbar-inverse .navbar-nav > .open > span:focus,
.navbar-inverse .navbar-nav > .open > span:hover {
  color: #fff;
  background-color: #080808;
}

.navbar-inverse .navbar-nav > li > span {
	color: #9d9d9d;
}

.navbar-inverse .navbar-nav > li > span:focus,
.navbar-inverse .navbar-nav > li > span:hover {
  color: #fff;
  background-color: transparent;
}